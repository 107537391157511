var DashBoardStats = function () {
    return {
        init: function () {
            $('#dashboard-stats').load(window.dashBoardStatsUrl);
        },

        search: function (searchString) {
            function buildUrl(base, key, value) {
                var separator = (base.indexOf('?') > -1) ? '&' : '?';
                return base + separator + key + '=' + value;
            }

            $('#dashboard-stats').load(buildUrl(window.dashBoardStatsUrl, 'search', searchString));
        }
    };
}();
