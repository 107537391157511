var PortletDraggable = function () {

    return {
        //main function to initiate the module
        init: function () {

            if (!jQuery().sortable) {
                return;
            }

            $( "#sortable" ).sortable();
            $( "#sortable" ).disableSelection();
        }
    };
}();