/* jshint browser: true, undef: true, unused: true, eqnull:true, -W033, -W117, -W098 */
var TableAdvanced = function () {

    var initTable = function () {
        var table = $('#sample_4');
        function replaceAll(string, find, replace) {
            string = String(string);
            if (string === undefined || string === "") {

                return string;
            }
            return string.replace(new RegExp(find, 'g'), replace);
        }


        /* Formatting function for row expanded details */
        function fnFormatDetails(userId) {
            var sOut = '';
            var glue = '?';
            if (window.getDomainsJson.indexOf("?") > -1) {
                glue = '&';
            }
            var url = window.getDomainsJson + glue + 'user=' + userId;
            $.ajax(url, {async: false}).done(function (data) {
                sOut = data;

            });

            return sOut;
        }

        function domainDetailsInDate(date, urlToGo) {
            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            date = date.replace(pattern,'$3-$2-$1');
            var sOut = '';
            var glue = '?';
            if (urlToGo.indexOf("?") > -1) {
                glue = '&';
            }
            var url = urlToGo + glue + 'start_day=' + date + '&end_day=' + date;
            $.ajax(url, {async: false}).done(function (data) {
                sOut = data;
            });

            return sOut;
        }

        function showDetailUniques(type, id) {
            var glue = '?';
            if (window.getGeosUrl.indexOf("?") > -1) {
                glue = '&';
            }
            var url = window.getGeosUrl + glue + 'type=' + type + '&id=' + id;
            var sOut = '';
            $.ajax(url, {async: false}).done(function (data) {
                sOut = data;
            });

            return sOut;
        }

        function showCidDetail(id) {
            var glue = '?';
            if (window.getCidUrl.indexOf("?") > -1) {
                glue = '&';
            }
            var url = window.getCidUrl + glue + '&id=' + id;
            var sOut = '';
            $.ajax(url, {async: false}).done(function (data) {
                sOut = data;
            });

            return sOut;
        }

        hideShowColumns();

        var defaultSort = [3, 'desc'];
        if (typeof isDashbaordUser !== "undefined") {
            defaultSort = [1, 'desc'];
        }

        var oTable = table.DataTable({
            "processing": true,
            "bSortCellsTop": true,
            "serverSide": true,
            "ajax": window.getStatsJson,

            "columnDefs": [
                {
                    "orderable": false,
                    "targets": [0]
                }
            ],
            "aoColumns": [
                null,
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
                { "orderSequence": [ "desc", "asc" ] },
            ],
            "order": [
                defaultSort
            ],
            "lengthMenu": [
                [5, 15, 20, 40, 60, 80, 100, -1],
                [5, 15, 20, 40, 60, 80, 100, "All"] // change per page values here
            ],
            "pageLength": 20,
            "drawCallback": function( ) {

                window.dataTableLoaded = true;
                callHeaderFix();
                hideShowColumns();
            },
            "preDrawCallback": function( ) {
                window.dataTableLoaded = false;
            }
        });

        function callHeaderFix() {
            $(".FixedHeader_Cloned").remove();
            window.fixedTable = new $.fn.dataTable.FixedHeader( $('#sample_4'), {
                'offsetTop' : 40
            });
        }

        table.on('search.dt', function () {
            DashBoardStats.search(oTable.search());
        });

        table.on('click', ' tbody td .row-details', function () {
            var nTr = $(this).parents('tr')[0];
            if ($(this).hasClass("row-details-open")) {
                $(this).addClass("row-details-close").removeClass("row-details-open");
                $(nTr).nextUntil('tr[role="row"]').hide();
                $(nTr).find('.row-details-uniques-open').addClass("row-details-uniques-close").removeClass("row-details-uniques-open");
                $(nTr).nextUntil(":not('tr.detail-tr')").find('.row-details-uniques-open').addClass("row-details-uniques-close").removeClass("row-details-uniques-open");
                $(nTr).nextUntil(":not('tr.detail-tr')").find('.row-details-cid-open').addClass("row-details-cid-close").removeClass("row-details-cid-open");
            } else {
                $(this).addClass("row-details-open").removeClass("row-details-close");
                var nextTr = $(nTr);
                if ($(nTr).next().hasClass('detail-tr-uniques')) {
                    nextTr = $(nTr).next();
                }

                if ($(".detail_user_" + $(this).data('user')).length > 0) {
                    $(".detail_user_" + $(this).data('user')).show();
                } else {
                    var ei = '';
                    if (typeof $(this).data('user') !== "undefined") {
                        ei = fnFormatDetails($(this).data('user'));
                    } else {
                        ei = domainDetailsInDate($(this).data('date'), window.getDomainsJson);
                    }

                    nextTr.after(ei);
                }
                hideShowColumns();
            }
            callHeaderFix();
        });

        table.on('click', ' tbody td .row-details-uniques', function () {
            var nTr = $(this).parents('tr')[0];
            if ($(this).hasClass("row-details-uniques-open")) {
                $(this).addClass("row-details-uniques-close").removeClass("row-details-uniques-open");
                $('#geo_' + replaceAll($(this).data('id'), ',', '_') + "_type_" + $(this).data('type')).hide();
            } else {
                /* Open this row */
                $(this).addClass("row-details-uniques-open").removeClass("row-details-uniques-close");
                if ($('#geo_' + replaceAll($(this).data('id'), ',', '_') + "_type_" + $(this).data('type')).length > 0) {
                    $('#geo_' + replaceAll($(this).data('id'), ',', '_') + "_type_" + $(this).data('type')).show();
                } else {
                    var ei = '';
                    if (typeof $(this).data('date') !== "undefined") {
                        ei = domainDetailsInDate($(this).data('date'), window.getGeosUrl);
                    } else {
                        ei = showDetailUniques($(this).data('type'), $(this).data('id'));
                    }
                    $(nTr).after(ei);
                }
            }
            callHeaderFix();
        });

        table.on('click', ' tbody td .row-details-cid', function () {
            var nTr = $(this).parents('tr')[0];
            if ($(this).hasClass("row-details-cid-open")) {
                $(this).addClass("row-details-cid-close").removeClass("row-details-cid-open");
                $('#cid_' + replaceAll($(this).data('id'), ',', '_')).hide();
            } else {
                /* Open this row */
                $(this).addClass("row-details-cid-open").removeClass("row-details-cid-close");
                if ($('#cid_' + replaceAll($(this).data('id'), ',', '_')).length > 0) {
                    $('#cid_' + replaceAll($(this).data('id'), ',', '_')).show();
                } else {
                    var ei = '';
                    if (typeof $(this).data('date') !== "undefined") {
                        ei = domainDetailsInDate($(this).data('date'), window.getCidUrl);
                    } else {
                        ei = showCidDetail($(this).data('id'));
                    }
                    $(nTr).after(ei);
                }


            }
            callHeaderFix();
        });

        table.on('change', '#country-geos select', function () {
            var nTr = $(this).parents('#country-geos')[0];
            if ($(this).val().toLowerCase() == 'bad') {
                $(nTr).find('tbody tr').hide();
                $(nTr).find('tbody tr.unique-bad-geo').show();
            } else {
                if ($(this).val().toLowerCase() == 'good') {
                    $(nTr).find('tbody tr').hide();
                    $(nTr).find('tbody tr.unique-good-geo').show();
                } else {
                    $(nTr).find('tbody tr').show();
                }
            }
            callHeaderFix();
        });

        $('#sample_4_column_toggler .checker').click(function () {
            hideShowColumns();
            callHeaderFix();
        });

        function hideShowColumns() {
            $('#sample_4').find('td, th').show();
            $('.FixedHeader_Cloned').find('td, th').show();
            $('#sample_4_column_toggler .checker span').each(function (index) {
                if (!$(this).hasClass('checked')) {
                    $('#sample_4 tr').each(function () {
                        $(this).find('td:nth-child(' + (index + 1) + ')').hide();
                    });
                    $('#sample_4 th:nth-child(' + (index + 1) + ')').hide();
                    $('.FixedHeader_Cloned th:nth-child(' + (index + 1) + ')').hide();
                }
            });

            $('.FixedHeader_Cloned').css('overflow', 'hidden');
            $('.FixedHeader_Cloned table').width($('#sample_4').width());

            col = 0;
            $('#sample_4 tr:first th').each(function (index) {
                tdWidth = $(this).width();
                selector = '.FixedHeader_Cloned tr:eq(1) th:eq('+ col + ')';

                if ($(selector).hasClass('sorting')) {
                    tdWidth -= 10;
                }
                if ($(selector).hasClass('sorting_desc')) {
                    tdWidth -= 10;
                }


                selector = '.FixedHeader_Cloned tr:first th:eq('+ col + ')';
                $(selector).width(tdWidth);
                $(selector).attr('width', tdWidth);
                $(selector).css('max-width', tdWidth);
                $(selector).css('min-width', tdWidth);

                selector = '.FixedHeader_Cloned tr:eq(1) th:eq('+ col + ')';
                $(selector).width(tdWidth);
                $(selector).attr('width', tdWidth);
                $(selector).css('max-width', tdWidth);
                $(selector).css('min-width', tdWidth);

                col++;
            });

            $('.FixedHeader_Cloned').width($('.table-scrollable').width());
            $('.FixedHeader_Cloned').css('overflow', 'hidden');


            $( ".table-scrollable" ).scroll(function() {
                $(".FixedHeader_Cloned table").css('position',  'relative');
                $(".FixedHeader_Cloned table").css('left',  $(".table-scrollable").scrollLeft() * -1);
            });
        }
    }

    function dolarFormatter(v) {
        return "$" + v.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function borderFormatter(v) {
        return v.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function nodecimalsFormatter(v) {
        return v.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function basicPercentage(value, total)
    {
        percentage = '0';
        if (total > 0) {
            percentage = (value * 100 / total).toFixed(2);
        }

        return percentage;
    }


    var initStatisticsWebmasterByDate = function () {
        var table = $('#statistics_webmaster');

        table.dataTable({
            "processing": true,
            "serverSide": true,
            "filter": false,
            "ajax": window.getOwnStatsJson,
            "pageLength": 30,
            "lengthMenu": [
                [5, 15, 30, -1],
                [5, 15, 30, "All"] // change per page values here
            ],
            "pagingType": "bootstrap_full_number",
            "language": {
                "lengthMenu": "  _MENU_ records",
                "paginate": {
                    "previous":"Prev",
                    "next": "Next",
                    "last": "Last",
                    "first": "First"
                }
            },
            "columns": [{
                "orderable": false
            }, {
                "orderable": true,
                "name": "statDate"
            }, {
                "orderable": true,
                "name": "goodGeoUniques"
            }, {
                "orderable": true,
                "name": "goodGeoPayment"
            }, {
                "orderable": true,
                "name": "badGeoUniques"
            }, {
                "orderable": true,
                "name": "badGeoPayment"
            }, {
                "orderable": true,
                "name": "deadVideoHits"
            }, {
                "orderable": true,
                "name": "pageViewsUser"
            }, {
                "orderable": true,
                "name": "uniqueVisits"
            }, {
                "orderable": true,
                "name": "totalPayment"
            }, {
                "orderable": true,
                "name": "avgCampaignCost"
            }],
            "order": [
                [1, "desc"]
            ],
            "drawCallback": function(settings, json) {
                window.dataTableLoaded = true;
            },
            "preDrawCallback": function(settings, json) {
                window.dataTableLoaded = false;
            },
            "footerCallback": function ( row, data, start, end, display ) {
                var api = this.api();
                // Remove the formatting to get integer data for summation
                var intVal = function ( i ) {
                    return typeof i === 'string' ?
                    i.replace(/ *\([^)]*\) */g, "").replace(/[\$,]/g, '')*1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var removeHtmlAndGetInt = function ( i ) {
                    return typeof i === 'string' ?
                    i.slice(0, -4).match(/\d+$/)*1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalRowsPrinted = api.column(0).data().length;

                pageTotalCol8 = api
                    .column(8, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(8).footer()).html(nodecimalsFormatter(pageTotalCol8));

                pageTotalCol2 = api
                    .column(2, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(2).footer()).html(nodecimalsFormatter(pageTotalCol2) + ' ('+basicPercentage(pageTotalCol2, pageTotalCol8)+'%)');

                pageTotalCol3 = api
                    .column(3, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(3).footer()).html(dolarFormatter(pageTotalCol3));

                pageTotalCol4 = api
                    .column(4, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(4).footer()).html(nodecimalsFormatter(pageTotalCol4) + ' ('+basicPercentage(pageTotalCol4, pageTotalCol8)+'%)');

                pageTotalCol5 = api
                    .column(5, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(5).footer()).html(dolarFormatter(pageTotalCol5));

                /* dead videos */
                pageTotalCol6 = api
                    .column(6, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return removeHtmlAndGetInt(a) + removeHtmlAndGetInt(b);
                    }, 0);
                $(api.column(6).footer()).html(nodecimalsFormatter(pageTotalCol6));

                pageTotalCol7 = api
                    .column(7, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(7).footer()).html(borderFormatter(pageTotalCol7/totalRowsPrinted));

                pageTotalCol9 = api
                    .column(9, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(9).footer()).html(dolarFormatter(pageTotalCol9));

                pageTotalCol10 = api
                    .column(10, {page: 'current'})
                    .data()
                    .reduce( function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(10).footer()).html(dolarFormatter(pageTotalCol10/totalRowsPrinted));


            }
        });

        function domainDetailsInDate(date) {
            var sOut = '';
            var glue = '?';
            if (window.getOwnStatsJson.indexOf("?") > -1) {
                glue = '&';
            }
            var url = window.getOwnStatsJson + glue + 'detail_date=' + date;
            $.ajax(url, {async: false}).done(function (data) {
                sOut = data;
            });

            return sOut;
        }

        /* Add event listener for opening and closing details
         * Note that the indicator for showing which row is open is not controlled by DataTables,
         * rather it is done here
         */
        table.on('click', ' tbody td .row-details', function () {
            var nTr = $(this).parents('tr')[0];
            if ($(this).hasClass("row-details-open")) {
                /* This row is already open - close it */
                $(this).addClass("row-details-close").removeClass("row-details-open");
                $(nTr).nextUntil(":not('tr.detail-tr')").hide();
            } else {
                /* Open this row */
                $(this).addClass("row-details-open").removeClass("row-details-close");
                if ($(nTr).next().hasClass('detail-tr')) {
                    $(nTr).nextUntil(":not('tr.detail-tr')").show();
                } else {
                    var ei = domainDetailsInDate($(this).parent().parent().find('td:nth-child(2)').text());
                    $(nTr).after(ei);
                }
            }
        });
    }

    var initStatisticsWebmasterByDomain = function () {
        var table = $('#statistics_webmaster_domains');

        table.dataTable({
            "processing": true,
            "serverSide": true,
            "filter": false,
            "ajax": window.getOwnDomainStatsJson,
            "pageLength": 20,
            "lengthMenu": [
                [5, 10, 20, -1],
                [5, 10, 20, "All"] // change per page values here
            ],
            "pagingType": "bootstrap_full_number",
            "language": {
                "lengthMenu": "  _MENU_ records",
                "paginate": {
                    "previous": "Prev",
                    "next": "Next",
                    "last": "Last",
                    "first": "First"
                }
            },
            "columns": [{
                "orderable": true,
                "name": "domain"
            }, {
                "orderable": true,
                "name": "goodGeoUniques"
            }, {
                "orderable": true,
                "name": "goodGeoPayment"
            }, {
                "orderable": true,
                "name": "badGeoUniques"
            }, {
                "orderable": true,
                "name": "badGeoPayment"
            }, {
                "orderable": true,
                "name": "deadVideoHits"
            }, {
                "orderable": true,
                "name": "pageViewsUser"
            }, {
                "orderable": true,
                "name": "uniqueVisits"
            }, {
                "orderable": true,
                "name": "totalPayment"
            }, {
                "orderable": true,
                "name": "avgCampaignCost"
            }],
            "order": [
                [0, "asc"]
            ],
            "drawCallback": function(settings, json) {
                window.dataTableLoaded = true;
            },
            "preDrawCallback": function(settings, json) {
                window.dataTableLoaded = false;
            }
        });
    }

    return {

        //main function to initiate the module
        init: function () {

            if (!jQuery().dataTable) {
                return;
            }

            if ($('#sample_4').length > 0) {
                initTable();
            }

            var initStats = 0;
            if ($('#statistics_webmaster').length > 0 && window.location.hash != '#domain') {
                initStatisticsWebmasterByDate();
                initStats = 1;
            }

            $("a[href='#date']").click(function() {
                window.location.hash = this.hash;
                if (initStats === 0) {
                    initStatisticsWebmasterByDate();
                    initStats = 1;
                }
            });

            var initDomains = 0;
            if ($('#statistics_webmaster_domains').length > 0 && window.location.hash == '#domain') {
                initStatisticsWebmasterByDomain();
                initDomains = 1;
            }

            $("a[href='#domain']").click(function() {
                window.location.hash = this.hash;
                if (initDomains === 0) {
                    initStatisticsWebmasterByDomain();
                    initDomains = 1;
                }
            });

        }

    };

}();

