/* jshint browser: true, undef: true, unused: true, eqnull:true, -W033, -W117, -W098 */
var TableManaged = function () {

    var initUserDomainsTable = function () {

        var table = $('#users_domain_table');

        // begin first table
        table.dataTable({
            "processing": true,
            "serverSide": true,
            "ajax": window.getDomainsJson,
            "columns": [{
                "orderable": false
            }, {
                "orderable": true,
                "name": "domain"
            }, {
                "orderable": true,
                "name": "createdAt"
            }, {
                "orderable": true,
                "name": "isValidated"
            }, {
                "orderable": false
            }, {
                "orderable": false
            }],
            "lengthMenu": [
                [5, 15, 20, -1],
                [5, 15, 20, "All"] // change per page values here
            ],
            // set the initial value
            "pageLength": 20,
            "pagingType": "bootstrap_full_number",
            "language": {
                "lengthMenu": "  _MENU_ records",
                "paginate": {
                    "previous":"Prev",
                    "next": "Next",
                    "last": "Last",
                    "first": "First"
                }
            },
            "columnDefs": [{  // set default column settings
                'orderable': false,
                'targets': [0]
            }, {
                "searchable": false,
                "targets": [0]
            }],
            "order": [
                [2, "desc"]
            ],
            "drawCallback": function() {
                window.dataTableLoaded = true;
            },
            "preDrawCallback": function() {
                window.dataTableLoaded = false;
            }
        });

        var tableWrapper = jQuery('#users_domain_table_wrapper');

        table.find('.group-checkable').change(function () {
            var set = jQuery(this).attr("data-set");
            var checked = jQuery(this).is(":checked");
            jQuery(set).each(function () {
                if (checked) {
                    $(this).attr("checked", true);
                    $(this).parents('tr').addClass("active");
                    $('#group-actions').show();
                } else {
                    $(this).attr("checked", false);
                    $(this).parents('tr').removeClass("active");
                    $('#group-actions').hide();
                }
            });
            jQuery.uniform.update(set);
        });

        table.on('change', 'tbody tr .checkboxes', function () {
            $(this).parents('tr').toggleClass("active");
            if ($('tr.active').length > 0) {
                $('#group-actions').show();
            } else {
                $('#group-actions').hide();
            }
        });

        tableWrapper.find('.dataTables_length select').addClass("form-control input-xsmall input-inline"); // modify table per page dropdown
    }

    var initAdminDomainsTable = function () {

        var table = $('#admin_domain_table');

        // begin first table
        table.dataTable({
            "processing": true,
            "serverSide": true,
            "ajax": window.getDomainsJson,
            "pageLength": 20,
            "lengthMenu": [
                [5, 15, 20, -1],
                [5, 15, 20, "All"] // change per page values here
            ],
            "pagingType": "bootstrap_full_number",
            "language": {
                "lengthMenu": "  _MENU_ records",
                "paginate": {
                    "previous":"Prev",
                    "next": "Next",
                    "last": "Last",
                    "first": "First"
                }
            },
            "columns": [{
                "orderable": false
            }, {
                "orderable": true,
                "name": "User.username"
            }, {
                "orderable": true,
                "name": "Domain.domain"
            }, {
                "orderable": true,
                "name": "Domain.createdAt"
            }, {
                "orderable": true,
                "name": "Domain.isValidated"
            }, {
                "orderable": false
            }, {
                "orderable": false
            }, {
                "orderable": false
            }],
            "order": [
                [3, "desc"]
            ],
            "drawCallback": function() {
                window.dataTableLoaded = true;
            },
            "preDrawCallback": function() {
                window.dataTableLoaded = false;
            }
        });

        var tableWrapper = jQuery('#admin_domain_table_wrapper');

        table.find('.group-checkable').change(function () {
            var set = jQuery(this).attr("data-set");
            var checked = jQuery(this).is(":checked");
            jQuery(set).each(function () {
                if (checked) {
                    $(this).attr("checked", true);
                    $(this).parents('tr').addClass("active");
                    $('#group-actions').show();
                } else {
                    $(this).attr("checked", false);
                    $(this).parents('tr').removeClass("active");
                    $('#group-actions').hide();
                }
            });
            jQuery.uniform.update(set);
        });

        table.on('change', 'tbody tr .checkboxes', function () {
            $(this).parents('tr').toggleClass("active");
            if ($('tr.active').length > 0) {
                $('#group-actions').show();
            } else {
                $('#group-actions').hide();
            }
        });

        tableWrapper.find('.dataTables_length select').addClass("form-control input-xsmall input-inline"); // modify table per page dropdown
    }

    var initUsersAdministrationTable = function () {

        var table = $('#users_administration');

        // begin first table
        table.dataTable({
            "processing": true,
            "serverSide": true,
            "ajax": window.getUsersJson,
            "columns": [{
                "orderable": false
            }, {
                "orderable": true,
                "name": "username"
            }, {
                "orderable": false
            }, {
                "orderable": true,
                "name": "createdAt"
            }, {
                "orderable": false
            }, {
                "orderable": false
            }, {
                "orderable": false
            }],
            "lengthMenu": [
                [5, 15, 20, -1],
                [5, 15, 20, "All"] // change per page values here
            ],
            // set the initial value
            "pageLength": 20,
            "pagingType": "bootstrap_full_number",
            "language": {
                "lengthMenu": "  _MENU_ records",
                "paginate": {
                    "previous":"Prev",
                    "next": "Next",
                    "last": "Last",
                    "first": "First"
                }
            },
            "columnDefs": [{  // set default column settings
                'orderable': false,
                'targets': [0]
            }, {
                "searchable": false,
                "targets": [0]
            }],
            "order": [
                [1, "asc"]
            ],
            "drawCallback": function() {
                window.dataTableLoaded = true;
            },
            "preDrawCallback": function() {
                window.dataTableLoaded = false;
            }
        });

        var tableWrapper = jQuery('#users_administration_wrapper');

        table.find('.group-checkable').change(function () {
            var set = jQuery(this).attr("data-set");
            var checked = jQuery(this).is(":checked");
            jQuery(set).each(function () {
                if (checked) {
                    $(this).attr("checked", true);
                    $(this).parents('tr').addClass("active");
                    $('#group-actions').show();
                } else {
                    $(this).attr("checked", false);
                    $(this).parents('tr').removeClass("active");
                    $('#group-actions').hide();
                }
            });
            jQuery.uniform.update(set);
        });

        table.on('change', 'tbody tr .checkboxes', function () {
            $(this).parents('tr').toggleClass("active");
            if ($('tr.active').length > 0) {
                $('#group-actions').show();
            } else {
                $('#group-actions').hide();
            }
        });

        tableWrapper.find('.dataTables_length select').addClass("form-control input-xsmall input-inline"); // modify table per page dropdown
    }

    var initPaymentsTable = function () {

        var table = $('#payments_table');

        // begin first table
        table.dataTable({
            "processing": true,
            "serverSide": true,
            "ajax": window.getPaymentsJson,
            "columns": [
            {
                "orderable": true,
                "name": "Payment.id"
            }, {
                "orderable": true,
                "name": "user_id"
            }, {
                "orderable": true,
                "name": "periodStart"
            }, {
                "orderable": true,
                "name": "amount"
            }, {
                "orderable": true,
                "name": "paid"
            }, {
                "orderable": true,
                "name": "paymentMethod"
            }, {
                "orderable": false
            }],
            "lengthMenu": [
                [5, 15, 20, -1],
                [5, 15, 20, "All"] // change per page values here
            ],
            // set the initial value
            "pageLength": 20,
            "pagingType": "bootstrap_full_number",
            "language": {
                "lengthMenu": "  _MENU_ records",
                "paginate": {
                    "previous":"Prev",
                    "next": "Next",
                    "last": "Last",
                    "first": "First"
                }
            },
            "order": [
                [0, "desc"]
            ],
            "drawCallback": function() {
                window.dataTableLoaded = true;
            },
            "preDrawCallback": function() {
                window.dataTableLoaded = false;
            }
        });
    }

    return {

        //main function to initiate the module
        init: function () {
            if (!jQuery().dataTable) {
                return;
            }
            window.dataTableLoaded = false;

            if ($('#users_administration').length > 0) {
                initUsersAdministrationTable();
            }
            if ($('#users_domain_table').length > 0) {
                initUserDomainsTable();
            }
            if ($('#admin_domain_table').length > 0) {
                initAdminDomainsTable();
            }

            if ($('#payments_table').length > 0) {
                initPaymentsTable();
            }
        },

        initDeleteConfirmation: function() {
            jQuery('body').on('click', '.filter-cancel', function (e) {
                e.preventDefault();
                window.hrefToGo = $(this).data('href');
                $('#domain-delete-confirmation').modal()
            });
            jQuery('body').on('click', '.delete-domain', function (e) {
                if (typeof window.hrefToGo === "undefined") {
                    window.massDelete = true;
                    $('.mass-remove-button').click();
                } else {
                    location.href = window.hrefToGo;
                }
            });

            jQuery('body').on('click', '.mass-remove-button', function (e) {
                if (typeof window.massDelete === "undefined") {
                    e.preventDefault();
                    $('#domain-delete-confirmation').modal();
                }
            });
        }

    };

}();
