var Index = function () {


    return {

        initDashboardDaterange: function () {
            $('#dashboard-report-range').daterangepicker({
                opens: 'right',
                startDate: new Date(startDay),
                endDate: new Date(endDay),
                minDate: moment().subtract('days', 1460),
                maxDate: moment().add('days', window.maxDate),
                showDropdowns: false,
                showWeekNumbers: true,
                timePicker: false,
                timePickerIncrement: 1,
                timePicker12Hour: true,
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract('days', 1), moment().subtract('days', 1)],
                    'Last 7 Days': [moment().subtract('days', 6), moment()],
                    'Last 30 Days': [moment().subtract('days', 29), moment()]
                },
                buttonClasses: ['btn'],
                applyClass: 'btn-success',
                cancelClass: 'btn-default',
                format: 'MM/DD/YYYY',
                separator: ' to ',
                locale: {
                    applyLabel: 'Apply',
                    fromLabel: 'From',
                    toLabel: 'To',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    firstDay: 1
                }
            },
            function (start, end) {
                $('#dashboard-report-range span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
                var query_string = '';
                if(document.location.search !== '') {
                    query_string = document.location.search.substr(1).split('&');
                    var i = query_string.length;
                    var current;
                    var start_found = false;
                    var end_found = false;
                    var key;

                    while(i--) {
                        current = query_string[i].split('=');
                        key = current[0];
                        if(key == 'start_day'){
                            start_found = true;
                            current[1] = start.format('YYYY-M-D');
                            query_string[i] = current.join('=');
                        }else if(key == 'end_day') {
                            end_found = true;
                            current[1] = end.format('YYYY-M-D');
                            query_string[i] = current.join('=');
                        }
                        if(i<0) {
                            query_string[query_string.length] = [key,current[1]].join('=');
                        }
                    }
                    if(start_found === false) {
                        query_string.push('start_day='+start.format('YYYY-M-D'));
                    }
                    if(end_found === false) {
                        query_string.push('end_day='+end.format('YYYY-M-D'));
                    }

                } else {
                    query_string = ['start_day='+start.format('YYYY-M-D'),'end_day='+end.format('YYYY-M-D')];
                }

                document.location.search = query_string.join('&');
            }
            );

            $('#dashboard-report-range span').html(moment(startDay).format('MMMM D, YYYY') + ' - ' + moment(endDay).format('MMMM D, YYYY'));
            $('#dashboard-report-range').show();
        }
    };

}();