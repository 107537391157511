var BlockDomain = function () {

    return {
        initBlockingForm: function () {
            $('#forever_block').change(function () {
                var endDateDiv = jQuery('#end_date_div');
                if (this.checked) {
                    endDateDiv.addClass('hidden');
                } else {
                    endDateDiv.removeClass('hidden');
                }
            });
        }
    };

}();