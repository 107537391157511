var Export = function () {

    return {
        init: function () {
            $('.select-all').click(function(){
                $('.checkbox-inline .checker span').each(function(index){
                    $(this).addClass('checked');
                });
                updateExportUrl();
            });
            $('.clear-all').click(function(){
                $('.checkbox-inline span.checked').removeClass();
                updateExportUrl();
            });

            $('#add-field li').click(function(){
                var fieldText = $(this).text().trim();
                var myArray = $("#sortable li span").map(function() {
                    return $(this).text().trim();
                }).get();

                if ($.inArray(fieldText, myArray) > -1){
                    $('#sortable li span:textEquals("'+fieldText+'")').parent().addClass('highlighted');
                    setTimeout(function(){
                      $('#sortable li span:textEquals("'+fieldText+'")').parent().removeClass('highlighted');}, 1000);
                } else {
                    $( "#sortable" ).append('<li class="btn btn-default"><span>'+fieldText+'</span> <a class="pull-right">x</a></li>');
                    updateExportUrl();
                }
            });

            $.expr[':'].textEquals = function(a, i, m) {
                return $(a).text().match("^" + m[3] + "$");
            };

            $('#sortable').on("click", "li.btn a", function(){
                $(this).parent().remove();
                updateExportUrl();
            });

            $("#options-export select").on("change", function() {
                updateExportUrl();
            });

            $("#sortable").on("DOMSubtreeModified", function() {
                updateExportUrl();
            });

            $('.checkbox-inline').click(function(){
                updateExportUrl();
            });

            $(document).on("click", ".download-export[download]", function () {
                $.fileDownload($(this).prop('href'),{
                    prepareCallback: function (url) {
                        $("#downloading-panel").show();$("#download-panel").hide();
                    }
                })
                .done(function () { $('#downloading-panel').hide();$('#download-panel').show(); })
                .fail(function () { $('#downloading-panel').hide();$('#download-panel').show(); $('#faileddownload-panel').show(); });

                return false; //this is critical to stop the click event which will trigger a normal file download
            });

            function getFieldOrder(separator) {
                var fieldOrder = [];
                $('#sortable li span').each(function(index){
                    fieldOrder[index] = $(this).text().trim().toLowerCase().replace(" ","");
                });

                return fieldOrder.join(separator);
            }

            function getCategoriesOrder() {
                var categorieOrder = [];

                $('#categories-list .checked input').each(function(index){
                    categorieOrder[index] = $(this).val();
                });

                if ($('#categories-list .checked input').length == $('#categories-list input').length) {
                    return 'all';
                }

                return categorieOrder.join('+');
            }

            function getOptions() {

                var optionExport = [];

                $('#options-export select option:selected').each(function(index){
                    optionExport[index] = $(this).parent().data('type') + '=' + $(this).val();
                });

                return optionExport.join('&');
            }

            function updateExportUrl() {
                var categories = getCategoriesOrder();

                if (categories === '') {
                    $('#exportURL').text('please select at least 1 category').removeAttr('href');
                    $('#sample-export span').text('please select at least 1 category');
                    $('.download-export').removeAttr('href').removeAttr('download');
                    return;
                }
                var updatedExportUrl = window.exportURL + '?cat=' + categories+ '&' + getOptions() + '&fields=' + getFieldOrder('+');

                $('.download-export').attr('href', updatedExportUrl + '&download=true').attr('download', 'download');

                $('#exportURL').text(updatedExportUrl).attr('href', updatedExportUrl).attr('target', '_blank');

                updateSampleExport();
                $('#faileddownload-panel').hide();

            }

            function updateSampleExport() {
                $('#sample-export p').text(getFieldOrder(getDelimiter()).toUpperCase().replace(" ",""));
                $('#sample-export span').text(exampleSample());
            }

            function exampleSample() {
                var arrayExample = [];
                arrayExample.id = '1';
                arrayExample.title = 'Example title';
                arrayExample.url = 'htpp://www.domain.com';
                arrayExample.thumb = 'http://cdn1b.thumbnails.domain.com/8/0/0/3/9/3/5/1/3/628x472/1.jpeg';
                arrayExample.tags = 'Tag1,Tag2';
                arrayExample.channels = 'Channel1';
                arrayExample.pornstars = 'Pornstar1';
                arrayExample['tags,channels,pornstars'] = 'Tag1,Tag2,Channel1,Pornstar1';
                arrayExample['rotating thumbs'] = 'http://cdn1b.thumbnails.domain.com/8/0/0/3/9/3/5/1/3/628x472/1.jpeg,http://cdn1b.thumbnails.domain.com/8/0/0/3/9/3/5/1/3/628x472/2.jpeg,http://cdn1b.thumbnails.domain.com/8/0/0/3/9/3/5/1/3/628x472/3.jpeg';
                arrayExample.duration = '300';
                arrayExample.date = '2015-02-04';
                arrayExample.embed = '<iframe width="640" height="480" src="http://www.domain.com/embed/1" frameborder="0" allowfullscreen></iframe><br><a href="http://www.domain.com/videos/video-title_1" rel="nofollow" target="_blank" title="Example title">Example title</a> - domain ®.com';


                var example = [];
                $('#sortable li span').each(function(index){
                    example[index] = arrayExample[$(this).text().trim().toLowerCase()];
                });

                return example.join(getDelimiter());
            }

            function getDelimiter() {
                var arrayDelimiter = [
                    "|", ",", ":", "    "
                ];

                var position = $('#options-export select[data-type="delimiter"] option:selected').val();

                return arrayDelimiter[position];
            }

        }
    };
}();
