var AdminVisualizationRules = function() {
    return {
        addRowListener: function() {
            jQuery('body').on('click', '.add_row', function (e) {
                event.preventDefault();
                var nextId = jQuery('#next_rule_id').val();
                jQuery('#add_row').before(appendRuleForm(nextId));
                var rulesIds = jQuery('#rules_ids').val();
                if (rulesIds === '') {
                    jQuery('#rules_ids').val(nextId);
                } else {
                    jQuery('#rules_ids').val(rulesIds + ',' + nextId);
                }
                jQuery('#next_rule_id').val(parseInt(nextId) + 1);
                $('.one-line-form input').uniform();
            });

            function appendRuleForm (ruleId) {
                return '<div class="col-md-12 one-line-form">\n' +
                    '<label for="' + ruleId + '_metric">When </label>\n' +
                    metricSelector.replace('newSelector', ruleId) + '\n' +
                    '<label for="' + ruleId + '_condition"> are</label>\n' +
                    '<select class="rule_selector" id="' + ruleId + '_condition" name="' + ruleId + '_condition"><option value="gt">greater than</option><option value="lt">less than</option><option value="bt">between</option></select>\n' +
                    '<input placeholder="Threshold value" name="' + ruleId + '_value_1" type="text">\n' +
                    '<label class="between none" for="' + ruleId + '_value_2">and</label>\n' +
                    '<input placeholder="Threshold value 2" name="' + ruleId + '_value_2" class="between none" type="text" id="' + ruleId + '_value_2">\n' +
                    '<label for="' + ruleId + '_color">mark as</label>\n' +
                    '<input name="' + ruleId + '_color" type="color" id="' + ruleId + '_color">\n' +
                    '<div class="col-md-1"></div>\n' +
                    '<label for="' + ruleId + '_notification">Notification rule:</label>\n' +
                    '<input name="' + ruleId + '_notification" type="checkbox" value="1" id="' + ruleId + '_notification">\n' +
                    '<label for="' + ruleId + '_delete">Delete rule:</label>\n' +
                    '<input name="' + ruleId + '_delete" type="checkbox" value="Delete" id="' + ruleId + '_delete">\n' +
                    '</div>';
            }
        },
        ruleSelectorListener: function() {
            jQuery('.rule_selector').each(function() {
                displayBetweenData(jQuery(this));
            });
            jQuery('body').on('change', '.rule_selector', function (e) {
                displayBetweenData(jQuery(this));
            });

            function displayBetweenData(elem) {
                var newRule = elem.val();
                if (newRule != 'bt') {
                    elem.parent().find('.between').hide();
                    elem.parent().find('input.between').val(null);
                }
                else {
                    elem.parent().find('.between').show();
                }
            }
        }
    };
}();
