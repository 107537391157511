/* jshint browser: true, undef: true, unused: true, eqnull:true, -W033, -W117, -W098 */
var PaymentAccount = function () {


    return {

        init: function () {
            var openPaymentHistory = 0;
            $('a[href="#paymentHistory"]').click(function(){
                if (openPaymentHistory === 0) {
                    PaymentAccount.loadPaymentTable();
                    openPaymentHistory = 1;
                }
            });
            hidePayments();
            $('#payment-method').change(function () {
               hidePayments();
            });
        },

        //main function
        loadPaymentTable: function () {
            var table = $('#payment_history_table');

            // begin first table
            table.dataTable({
                "processing": true,
                "serverSide": true,
                "ajax": window.getPaymentHistory,
                "pageLength": 20,
                "bFilter": false,
                "lengthMenu": [
                    [5, 15, 20, -1],
                    [5, 15, 20, "All"] // change per page values here
                ],
                "pagingType": "bootstrap_full_number",
                "language": {
                    "lengthMenu": "  _MENU_ records",
                    "paginate": {
                        "previous":"Prev",
                        "next": "Next",
                        "last": "Last",
                        "first": "First"
                    }
                },
                "columns": [{
                    "orderable": false
                }, {
                    "orderable": false,
                    "type": "numeric"
                }, {
                    "orderable": false
                }],
                "order": [
                    [0, "desc"]
                ]
            });

        }

    };

}();

function hidePayments() {
    $('.paxum-type').hide();
    $('.wire-type').hide();
    if ($('#payment-method').val() !== '') {
        $('.'+$('#payment-method').val()+'-type').show();
    }
}