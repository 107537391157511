/* jshint browser: true, undef: true, unused: true, eqnull:true, -W033, -W117, -W098 */
var UsersActions = function() {
    return {
        init: function() {
            jQuery('#users_administration_wrapper').on('click', '.single_action', function () {
                var method = jQuery(this).data('method');
                var objectId = jQuery(this).data('id');
                if (method === 'unBlockUsers') {
                    unBlockUsers([objectId]);
                }
                if (method === 'blockUsers') {
                    blockUsers([objectId]);
                }
                if (method === 'confirmEmails') {
                    confirmEmails([objectId]);
                }
            });
            jQuery('body').on('click', '.mass_action', function () {
                var related_class = jQuery(this).data('related-class');
                var method = jQuery(this).data('method');
                var idList = [];
                jQuery('.' + related_class + ':checked').each(function() {
                    idList.push(jQuery(this).val());
                });
                if (method === 'unBlockUsers') {
                    unBlockUsers(idList);
                }
                if (method === 'blockUsers') {
                    blockUsers(idList);
                }
                if (method === 'confirmEmails') {
                    confirmEmails(idList);
                }
            });
            function blockUsers(idList) {
                actionPostCall(window.blockUserUrl, idList);
            }
            function unBlockUsers(idList) {
                actionPostCall(window.unBlockUserUrl, idList);
            }
            function confirmEmails(idList) {
                actionPostCall(window.confirmUserEmails, idList);
            }
            function actionPostCall(url, idList) {
                var data = { ids:JSON.stringify(idList) };
                jQuery.post(url, data, function(response) {
                    var status = response.status;
                    if (status == 'OK') {
                        location.reload();
                    }
                });
            }

            $('body').on('show.bs.modal', '.domains-modal', function () {
                $('.modal .modal-body').css('overflow-y', 'auto');
                $('.modal .modal-body').css('max-height', $(window).height() * 0.7);
            });
        }
    }
}();

