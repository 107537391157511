/* jshint browser: true, undef: true, unused: true, eqnull:true, -W033, -W117, -W098 */
var IframeTable = function () {

    var initIframesTable = function () {

        var table = jQuery('#iframes_table_id');

        // begin first table
        table.dataTable({
            "processing": true,
            "serverSide": true,
            "ajax": window.getIframesJson,
            "columns": [ {
                "orderable": true,
                "name": "name"
            }, {
                "orderable": false
            }, {
                "orderable": false
            }, {
                "orderable": false
            }, {
                "orderable": false
            }, {
                "orderable": false
            }, {
                "orderable": false
            }],
            "lengthMenu": [
                [5, 15, 20, -1],
                [5, 15, 20, "All"] // change per page values here
            ],
            // set the initial value
            "pageLength": 20,
            "pagingType": "bootstrap_full_number",
            "language": {
                "lengthMenu": "  _MENU_ records",
                "paginate": {
                    "previous":"Prev",
                    "next": "Next",
                    "last": "Last",
                    "first": "First"
                }
            },
            "columnDefs": [{  // set default column settings
                'orderable': false,
                'targets': [0]
            }, {
                "searchable": false,
                "targets": [0]
            }],
            "order": [
                [2, "desc"]
            ],
            "drawCallback": function() {
                window.dataTableLoaded = true;
            },
            "preDrawCallback": function() {
                window.dataTableLoaded = false;
            }
        });

        var tableWrapper = jQuery('#iframes_table_id_wrapper');
        tableWrapper.find('.dataTables_length select').addClass("form-control input-xsmall input-inline"); // modify table per page dropdown
    }

    return {

        //main function to initiate the module
        init: function () {
            if (!jQuery().dataTable) {
                return;
            }
            window.dataTableLoaded = false;

            if ($('#iframes_table_id').length > 0) {
                initIframesTable();
            }
        }
    };
}();
