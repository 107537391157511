var Iframe = function () {

    return {
        init: function () {
            $('.select-all').click(function () {
                $('.checkbox-inline .checker span').each(function (index) {
                    $(this).addClass('checked');
                });
                updateIframePreview();
            });
            $('.clear-all').click(function () {
                $('.checkbox-inline span.checked').removeClass();
                updateIframePreview();
            });
            $('.checkbox-inline').click(function () {
                updateIframePreview();
            });
            $("#options-iframe select, #pornstars-list,  #link_color, #background_color, #text_color").on("change", function () {
                updateIframePreview();
            });
            $('#name').on('change', function(){
                updateIframePreview();
                if($(this).val() !== '') {
                    $('#name-group').removeClass('has-error');
                }
            });

            $('#save-iframe').click(function() {
                if($('#name').val() === '') {
                    $('#name-group').addClass('has-error').focus();
                    $('html, body').animate({
                        scrollTop: 0
                    }, 1000);
                    return;
                }
                $('#hidden_tags').val(getCategoriesOrder());
                $('#hidden_pornstar').val($('#pornstars-list').val());
                $('#iframe-form').submit();
            });

             function getCategoriesOrder() {
                var categorieOrder = [];

                $('#categories-list .checked input').each(function (index) {
                    categorieOrder[index] = $(this).val();
                });

                if ($('#categories-list .checked input').length == $('#categories-list input').length) {
                    return 'all';
                }

                return categorieOrder.join('+');
            }

            function getOptions() {

                var optionExport = [];

                $('#options-iframe select option:selected').each(function (index) {
                    if(typeof $(this).parent().data('type') != 'undefined') {
                        optionExport[index] = $(this).parent().data('type') + '=' + $(this).val();
                    }
                });

                return optionExport;
            }

            function getName() {
                var possibleValue = $('#name').val();
                if (possibleValue !== '') {
                    possibleValue = '/' + possibleValue;
                }

                return possibleValue;
            }

            function getColors() {
                var color_array = [];
                color_array[0] = 'link=' + $('#link_color').val().slice(1);
                color_array[1] = 'bg=' + $('#background_color').val().slice(1);
                color_array[2] = 'text=' + $('#text_color').val().slice(1);

                return color_array;
            }

            function getTagsAndPornstarSelected() {
                var categories = getCategoriesOrder();
                var query_string = [];
                var index = 0;
                if (categories !== '') {
                    query_string[index] = 'tag='+categories;
                    index++;
                }
                var pornstar = $('#pornstars-list').val();
                if (pornstar != 'none') {
                    query_string[index] = 'pornstar='+ pornstar;
                }

                return query_string;
            }

            function addAllParametersToUrl(url)
            {
                var tagsAndPornstars = getTagsAndPornstarSelected();
                var options = getOptions();
                var colors = getColors();
                var wmcid  = ['wmcid='+getCurrentWmCid()];
                var parameters = tagsAndPornstars.concat(options,colors,wmcid);

                url = url + '?' + parameters.join('&');

                return url;
            }

            function calculateHeight() {
                var count = $('#iframeRows :selected').val();
                var singleHeight = $('#thumbnailSizeSelect :selected').data('height');

                return count * singleHeight + 20;
            }

            function calculateWidth() {
                var count = $('#iframeColumns :selected').val();
                var singleWidth = $('#thumbnailSizeSelect :selected').data('width');

                return count * singleWidth + 40;
            }

            function getIframeCode(iframeUrl)
            {
                return '&lt;iframe src="'+iframeUrl+'" height="'+calculateHeight()+'" width="'+calculateWidth()+'" frameborder="0"&gt;&lt;/iframe&gt;';
            }

            function getCurrentWmCid() {
                var wmcid = window.currentName;
                if ($('#user_id').length) {
                    wmcid = $('#user_id :selected').text();
                }
                return wmcid;
            }

            function updateIframePreview() {
                var updatedIframeDemoUrl = addAllParametersToUrl(window.iframeURL);
                var updatedIframeUrl = 'You need to provide a name to be able to generate your iframe URL';
                var iframeCode = updatedIframeUrl;
                if (getName() !== '') {
                    var tagsAndPornstars = getTagsAndPornstarSelected();
                    tagsAndPornstars = tagsAndPornstars.join('&');
                    updatedIframeUrl = window.iframeURL + getName() + '?' + tagsAndPornstars + '&wmcid=' + getCurrentWmCid();
                    iframeCode = getIframeCode(updatedIframeUrl);
                }
                $('#iframeURL').html(updatedIframeUrl);
                $('#iframeCode').html(iframeCode);
                $('#sample-iframe').attr('href', updatedIframeDemoUrl);
                $('#sample-iframe').html(updatedIframeDemoUrl);
            }
        }
    };
}();
