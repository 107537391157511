var PeriodSelector = function() {
    return {
        init: function() {
            $('#periodSelector').popover({
                content: $('#periodSelectorOptions').html(),
                html: true
            });

            jQuery('body').on('click', '.period-selector', function (e) {
                $('#start_p').val('');
                $('#start_m').val('');
                $('#start_y').val('');
                $('#end_p').val('');
                $('#end_m').val('');
                $('#end_y').val('');
                $('#periodSelection').val($(this).data('value'));
            });

            function daysInMonth(iMonth, iYear)
            {
                return 32 - new Date(iYear, iMonth - 1, 32).getDate();
            }

            jQuery('body').on('click', '#apply-period', function (e) {
                var params = '';
                if ($('#periodSelection').val() !== '') {
                    params = 'period=' + $('#periodSelection').val();
                } else {
                    var start = '';
                    if ($('#start_p').val() == 'p1') {
                        start = $('#start_y').val() + '-' + $('#start_m').val() + '-' + '1';
                    } else if ($('#start_p').val() == 'p2') {
                        start = $('#start_y').val() + '-' + $('#start_m').val() + '-' + '16';
                    }
                    var end = '';
                    if ($('#end_p').val() == 'p1') {
                        end = $('#end_y').val() + '-' + $('#end_m').val() + '-' + '15';
                    } else if ($('#end_p').val() == 'p2') {
                        end = $('#end_y').val() + '-' + $('#end_m').val() + '-' + daysInMonth($('#end_m').val(), $('#end_y').val());
                    }

                    params = 'period_start=' + start + '&period_end=' + end;
                }

                window.location = '?' + params + window.location.hash;
            });
        }
    };
}();
