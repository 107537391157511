/* jshint browser: true, undef: true, unused: true, eqnull:true, -W033, -W117, -W098, -W046 */
var Payments = function() {

    return {
        initCharts: function() {
            if (!jQuery.plot) {
                return;
            }

            var data = [];
            var totalPoints = 250;

            function showTooltip(title, x, y, contents) {
                $('<div id="tooltip" class="chart-tooltip"><div class="date">' + title + '<\/div>' + contents + '<\/div>').css({
                    position: 'absolute',
                    display: 'none',
                    top: y - 80,
                    width: 120,
                    left: x - 45,
                    border: '0px solid #ccc',
                    padding: '2px 6px',
                    'background-color': '#fff'
                }).appendTo("body").fadeIn(200);
            }

            function dolarFormatter(v, axis) {
                return "$" + v.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            function borderFormatter(v, axis) {
                return v.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            var previousPoint = null;
            function plotHoverTooltip(item) {
                if (item) {
                        if (previousPoint !== item.dataIndex) {
                            previousPoint = item.dataIndex;

                            $("#tooltip").remove();

                            showTooltip('', item.pageX, item.pageY, item.series.label + ": " + item.series.yaxis.tickFormatter(item.datapoint[1], 'y'));

                        }
                    } else {
                        $("#tooltip").remove();
                        previousPoint = null;
                    }
            }


            if ($('#site_statistics').size() !== 0) {

                $('#site_statistics_loading').hide();
                $('#site_statistics_content').show();

                var plot_statistics = $.plot($("#site_statistics"), [{
                        data: window.pageviews,
                        label: "Visits"
                    }, {
                        data: window.payments,
                        label: "Total Paid",
                        yaxis: 2
                    }
                ], {
                    series: {
                        lines: {
                            show: true,
                            lineWidth: 2,
                            fill: true,
                            fillColor: {
                                colors: [{
                                        opacity: 0.05
                                    }, {
                                        opacity: 0.01
                                    }
                                ]
                            }
                        },
                        points: {
                            show: true
                        },
                        shadowSize: 2
                    },
                    grid: {
                        hoverable: true,
                        clickable: true,
                        tickColor: "#eee",
                        borderWidth: 0
                    },
                    colors: ["#d12610", "#37b7f3", "#52e136"],
                    xaxis: {
                        mode: "time"
                    },
                    yaxes: [{
                        ticks: 11,
                        tickDecimals: 0,
                        tickFormatter: borderFormatter
                    }, {
                        alignTicksWithAxis: 1,
                        position: 'doPlot',
                        tickDecimals: 2,
                        tickFormatter: dolarFormatter
                    }
                    ]
                });

                $("#site_statistics").bind("plothover", function(event, pos, item) {
                    plotHoverTooltip(item);
                });

            }

            if ($('#site_statistics_2').size() !== 0) {

                $('#site_statistics_loading_2').hide();
                $('#site_statistics_content_2').show();

                var plot_statistics2 = $.plot($("#site_statistics_2"), [{
                    data: window.uniqueVisits,
                    label: "Visits"
                }, {
                    data: window.rawPageViews,
                    label: "Pageviews",
                    yaxis: 1
                }
                ], {
                    series: {
                        lines: {
                            show: true,
                            lineWidth: 2,
                            fill: true,
                            fillColor: {
                                colors: [{
                                    opacity: 0.05
                                }, {
                                    opacity: 0.01
                                }
                                ]
                            }
                        },
                        points: {
                            show: true
                        },
                        shadowSize: 2
                    },
                    grid: {
                        hoverable: true,
                        clickable: true,
                        tickColor: "#eee",
                        borderWidth: 0
                    },
                    colors: ["#d1e399", "#92e48a", "#52e136"],
                    xaxis: {
                        mode: "time"
                    },
                    yaxis: {
                        ticks: 11,
                        tickDecimals: 0,
                        tickFormatter: borderFormatter
                    }
                });

                $("#site_statistics_2").bind("plothover", function(event, pos, item) {
                    plotHoverTooltip(item);
                });

            }
        },

        paymentDetails: function() {
            jQuery('body').on('click', '.show-payment-detail', function (e) {
                e.preventDefault();
                if ($(this).next('p').length === 0) {
                    $(this).after($('#payment-details-' + $(this).data('payment-id')).html());
                } else {
                    if ($(this).next('p').is(":visible")) {
                        $(this).next('p').hide();
                    } else {
                        $(this).next('p').show();
                    }
                }
            });
            jQuery('body').on('click', '.show-payment-hits-detail', function (e) {
                e.preventDefault();
                if ($(this).next('p').length === 0) {
                    $(this).after($('#payment-hits-detail-' + $(this).data('payment-id')).html());
                } else {
                    if ($(this).next('p').is(":visible")) {
                        $(this).next('p').hide();
                    } else {
                        $(this).next('p').show();
                    }
                }
            });

        },

        commentsPayments: function() {
            jQuery('body').on('click', '.comment', function (e) {
                e.preventDefault();
                if ($(this).next('p').length === 0) {
                    $(this).after('<p><textarea class="form-control" name="comment-' + $(this).data('payment-id') + '">' + $('#comment-' + $(this).data('payment-id')).html()+ '</textarea><button class="btn btn-default save-comment" data-payment-id="' + $(this).data('payment-id') + '">Save</button></p>');
                } else {
                    if ($(this).next('p').is(":visible")) {
                        $(this).next('p').hide();
                    } else {
                        $(this).next('p').show();
                    }
                }
            });

            $('#payments_table').on('click', '.save-comment', function(){
                window.ajaxRequestCompleted = false;
                var data = {
                    'commentId' :  $(this).data('payment-id'),
                    'comment' : $(this).parent().find(':input').val()
                };

                var paymentsTable = $(this);

                $.ajax({
                    url: window.paymentCommentUrl,
                    type: "POST",
                    data: data,
                    cache: false,
                    dataType: 'json'
                }).done(function(data) {
                    $(paymentsTable).parent().hide();
                    $('#comment-' + data.paymentId).html(data.comment);
                    window.ajaxRequestCompleted = true;
                });
            });
        },

        ajaxFunctions: function() {
            jQuery('body').on('click', '.change-paid-boolean-ajax-action', function (e) {
                event.preventDefault();
                window.ajaxRequestCompleted = false;
                $(this).html('processing...');
                var link = $(this);
                $.ajax({
                    url: $(this).attr('href'),
                    cache: false,
                    dataType: 'json'
                }).done(function(data) {
                    $(link).parent().find('span').removeClass('paid');
                    $(link).parent().find('span').removeClass('unpaid');
                    $(link).parent().find('span').removeClass('label-success');
                    $(link).parent().find('span').removeClass('label-default');

                    if (data.paid === true) {
                        $(link).parent().find('span').html('Paid');
                        $(link).parent().find('span').addClass('paid');
                        $(link).parent().find('span').addClass('label-success');
                        $(link).html('mark as unpaid');
                        $(link).attr('title', 'mark as unpaid ' + $(link).data('payment-id'));
                    } else {
                        $(link).parent().find('span').html('Not paid');
                        $(link).parent().find('span').addClass('unpaid');
                        $(link).parent().find('span').addClass('label-default');
                        $(link).html('mark as paid');
                        $(link).attr('title', 'mark as paid ' + $(link).data('payment-id'));
                    }
                    window.ajaxRequestCompleted = true;
                });
            });
        },
    }
}();
